import styled from 'styled-components';

/* Constants */
import { COLORS, FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS } from 'lib/constants';

export const Container = styled.section`
  padding: 110px 0 125px 0;

  @media screen and (max-width: 479px) {
    padding: 50px 0;
  }

  .history {
    &__cta {
      display: none;

      @media screen and (max-width: 479px) {
        padding: 50px 32px 0 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const Title = styled.div`
  display: flex;
  padding: 0 32px;
  position: relative;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  @media screen and (max-width: 843px) {
    display: flex;
    flex-direction: column-reverse;
  }

  h2 {
    flex: 1;
    grid-column: 1;
    color: ${COLORS.deepBlue};
    font-weight: ${FONT_WEIGHTS.weight1};
    font-size: ${FONT_SIZES.size4};
    line-height: ${LINE_HEIGHTS.height1};
    padding-bottom: 21px;
    padding-right: 24px;
    text-transform: uppercase;

    @media screen and (max-width: 479px) {
      padding-right: 0;
    }
    @media (min-width: 992px) {
      align-self: flex-end;
    }
  }

  .dash {
    position: absolute;
    left: 32px;
    bottom: 0;
    background-color: ${COLORS.deepBlue};
    height: 3px;
    width: 49px;
  }

  .anniversary-container {
    flex: 1;
    height: 130px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 843px) {
      justify-content: flex-start;
      margin-bottom: 50px;
    }

    @media screen and (max-width: 479px) {
      margin-bottom: 52px;
    }

    .anniversary {
      height: 130px;
      width: 130px;
      filter: drop-shadow(0 0 0.15rem rgba(0, 0, 0, 0.2));
    }
  }
`;

export const Paragraphs = styled.div`
  padding: 50px 32px 0 32px;

  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 1;
  }
  .paragraph:not(:last-child) {
    margin-bottom: 20px;
  }

  .paragraph ~ .paragraph {
    @media screen and (max-width: 768px) {
      margin-top: 48px;
      margin-bottom: 0px;
    }

    @media screen and (max-width: 863.98px) {
      margin-left: 0;
    }
  }

  .paragraph {
    text-align: justify;
    @media screen and (max-width: 767px) {
      flex-basis: 100%;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    column-gap: 20px;
    &.image-paragraph {
      grid-template-columns: 1fr;
    }
  }

  svg {
    stroke: ${COLORS.grey3};
  }

  p {
    color: ${COLORS.grey0};
    font-size: ${FONT_SIZES.size6};
    line-height: 29px;
    white-space: pre-line;
  }

  &.paragraphs--responsive {
    display: none;
  }

  @media screen and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    padding-top: 32px;

    .paragraph {
      display: none;
    }

    p:not(:first-child) {
      margin-top: 25px;
    }

    .cta {
      &.collapsed {
        p {
          margin-top: 32px;
        }
      }
    }

    &.paragraphs--responsive {
      display: block;
    }
  }

  .people-container {
    display: flex;
    @media (max-width: 479.98px) {
      display: none;
    }
    @media (min-width: 992px) {
      justify-content: flex-end;
    }
    &.people-mobile {
      display: flex;
      padding-top: 20px;
    }
    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      margin: 0 auto;
      @media screen and (max-width: 883.98px) {
        width: 100%;
      }
      @media screen and (max-width: 399.98px) {
      }
      @media (min-width: 884px) {
      }
      @media (min-width: 1200px) {
      }
    }
  }
`;
