import styled from 'styled-components';

/* Constants */
import { FONT_SIZES, COLORS } from 'lib/constants';

export const Container = styled.section`
  padding: 83px 0 125px 0;

  @media screen and (max-width: 595px) {
    padding: 83px 0 0 0;
  }

  > h2 {
    color: ${COLORS.deepBlue};
    text-transform: uppercase;
    font-size: ${FONT_SIZES.size4};
    position: relative;
    margin-bottom: 78px;
    margin-left: 32px;

    ::after {
      content: ' ';
      width: 49px;
      height: 3px;
      left: 0;
      bottom: -30px;
      position: absolute;
      background-color: ${COLORS.deepBlue};
    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 1;
      min-width: 290px;

      ~ div {
        margin-left: 16px;
      }
    }

    @supports (display: grid) {
      display: grid;
      grid-row-gap: 62px;
      grid-column-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

      > div {
        min-width: auto;

        ~ div {
          margin-left: 0;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }

    @media screen and (max-width: 991px) {
      grid-row-gap: 31px;
      padding: 0;
    }

    @media screen and (max-width: 595px) {
      grid-row-gap: 0;
    }
  }
`;
