import React, { useMemo } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

/* Components */
import { Container, Background } from './styled';
import Tag from 'components/ui/Tag';
import { Information, Network } from 'components/ui/Icons';

/* HOCs */
import withLine from 'components/ui/withLine';

/* Helpers */
import { addUniqueIds } from 'lib/helpers';

const LongDualTags = ({ intl }) => {
  const tags = useMemo(
    () =>
      addUniqueIds([
        {
          svg: <Network />,
          title: intl.formatMessage({ id: 'map.network.title' }),
        },
        {
          svg: <Information />,
          title: intl.formatMessage({ id: 'map.information.title' }),
        },
      ]),
    [intl]
  );

  return (
    <Background>
      <Container className={'container'}>
        {tags.map(service => (
          <Tag service={service} key={service.id} />
        ))}
      </Container>
    </Background>
  );
};

LongDualTags.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(withLine(LongDualTags));
