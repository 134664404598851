import React from 'react';

const SvgComponent = props => (
  <svg width={41} height={41} {...props} viewBox="0 0 41 41">
    <title>{'977EC8D2-E619-4BC8-A13D-0BB638290508'}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M20.52.32C9.376.32.36 9.336.36 20.48s9.016 20.16 20.16 20.16 20.16-9.016 20.16-20.16S31.664.32 20.52.32zM36.2 29.216h-5.096a32.044 32.044 0 001.064-7.616h6.272a19.413 19.413 0 01-2.24 7.616zM2.656 21.6h6.272a32.46 32.46 0 001.064 7.616H4.84A18.466 18.466 0 012.656 21.6zm2.184-9.856h5.096a32.044 32.044 0 00-1.064 7.616H2.656c.168-2.744.952-5.32 2.184-7.616zm16.8-2.24V2.672c2.52.616 4.76 3.192 6.272 6.832H21.64zm7.056 2.24a31.857 31.857 0 011.232 7.616H21.64v-7.616h7.056zM19.4 2.672v6.832h-6.272c1.512-3.64 3.752-6.216 6.272-6.832zm0 9.072v7.616h-8.288c.112-2.744.504-5.32 1.232-7.616H19.4zM11.112 21.6H19.4v7.616h-7.056a31.857 31.857 0 01-1.232-7.616zm8.288 9.856v6.832c-2.52-.616-4.76-3.192-6.272-6.832H19.4zm2.24 6.832v-6.832h6.272c-1.512 3.64-3.752 6.216-6.272 6.832zm0-9.072V21.6h8.288c-.112 2.744-.504 5.32-1.232 7.616H21.64zm10.528-9.856a32.46 32.46 0 00-1.064-7.616H36.2a17.716 17.716 0 012.24 7.616h-6.272zm2.52-9.856H30.32c-.84-2.24-1.904-4.2-3.192-5.656 3.024 1.176 5.6 3.192 7.56 5.656zM13.856 3.848c-1.288 1.512-2.352 3.416-3.192 5.656H6.296c2.016-2.464 4.592-4.48 7.56-5.656zM6.352 31.456h4.368c.84 2.24 1.904 4.2 3.192 5.656-3.024-1.176-5.6-3.192-7.56-5.656zm20.832 5.656c1.288-1.512 2.352-3.416 3.192-5.656h4.368c-2.016 2.464-4.592 4.48-7.56 5.656z"
        fill="#155CEA"
        fillRule="nonzero"
      />
      <path d="M-1.6-2.2h44.8v44.8H-1.6z" />
    </g>
  </svg>
);

export default SvgComponent;
