import React from 'react';

const RecoveryActions = props => (
  <svg width={56} height={57} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.41 40.73c.77 0 1.4.63 1.4 1.4 0 .77-.63 1.4-1.4 1.4H6.65c-2.31 0-4.2-1.89-4.2-4.2V7.83c0-2.31 1.89-4.2 4.2-4.2h34.23c2.31 0 4.2 1.89 4.2 4.2v16.52c0 .77-.63 1.4-1.4 1.4-.77 0-1.4-.63-1.4-1.4V7.83c0-.77-.63-1.4-1.4-1.4h-9.17v8.68c0 .63-.42 1.19-1.05 1.33l-4.34 1.26c-.28.07-.84.07-1.19-.21l-3.29-2.38-3.99 2.03c-1.05.49-2.03-.35-2.03-1.26V6.43H6.65c-.77 0-1.4.63-1.4 1.4v14.35h4.13c.77 0 1.4.63 1.4 1.4 0 .77-.63 1.4-1.4 1.4H5.25v2.8h1.33c.77 0 1.4.63 1.4 1.4 0 .77-.63 1.4-1.4 1.4H5.25v8.75c0 .77.63 1.4 1.4 1.4h25.76zm-3.5-34.3H18.62v7.14l2.73-1.4c.49-.21 1.12-.14 1.47.14l3.43 2.45 2.66-.77V6.43z"
      fill="#155CEA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.45 51.23h-.56c-.35-4.69-2.31-8.61-5.11-10.57 2.8-1.96 4.76-5.88 5.11-10.57h.56c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4H31.57c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h.7c.35 4.69 2.31 8.61 5.11 10.57-2.8 1.96-4.76 5.88-5.11 10.57h-.7c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h19.88c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4zM35.14 30.09h12.95c-.42 5.11-3.22 9.17-6.51 9.17-3.22 0-6.02-4.06-6.44-9.17zm6.44 11.97c-3.22 0-6.02 4.06-6.44 9.17h12.88c-.42-5.11-3.22-9.17-6.44-9.17z"
      fill="#155CEA"
    />
  </svg>
);

export default RecoveryActions;
