import styled from 'styled-components';

/* Constants */
import { COLORS, FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS } from 'lib/constants';

export const Container = styled.div`
  .dual-box {
    display: flex;
    flex-direction: column;

    &:hover {
      .dual-box__text {
        background-color: ${COLORS.darkBlue};

        span,
        p {
          color: white;
        }
      }

      .dual-box__image {
        img {
          filter: grayscale(0);
        }
      }
    }

    @media screen and (min-width: 992px) {
      height: 990px;
    }

    @media screen and (min-width: 1200px) {
      height: 850px;
    }

    @media screen and (min-width: 1400px) {
      height: 780px;
    }

    &.imageToTheLeft {
      flex-direction: column-reverse;

      @media screen and (max-width: 991.98px) {
        flex-direction: column;
      }
    }

    &__image {
      width: 100%;
      height: 50%;

      @media screen and (max-width: 991.98px) {
        height: 320px;
        width: 100%;
      }

      img {
        filter: grayscale(1);
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: filter 0.2s ease;

        @media screen and (max-width: 991px), (hover: none) {
          filter: grayscale(0);
        }
      }
    }

    &__text {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px 32px;
      transition: background-color 0.2s ease;
      height: 50%;

      @media screen and (max-width: 992.98px) {
        min-height: 250px;
      }

      @media screen and (max-width: 767px) {
        padding: 32px;
      }

      @media screen and (max-width: 600px) {
        padding: 50px 32px;
      }

      span {
        color: ${COLORS.darkBlue};
        font-size: ${FONT_SIZES.size1};
        line-height: ${LINE_HEIGHTS.height1};
        font-weight: ${FONT_WEIGHTS.weight1};
        padding-bottom: 22px;
        transition: color 0.2s ease;
      }

      p {
        color: ${COLORS.grey0};
        font-size: ${FONT_SIZES.size6};
        line-height: ${LINE_HEIGHTS.height2};
        transition: color 0.2s ease;
      }
    }
  }
`;
