import React from 'react';

const PropertyLoss = props => (
  <svg width={56} height={57} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.65 3.63h42c2.31 0 4.2 1.89 4.13 4.2v32.34c0 2.31-1.89 4.2-4.2 4.2h-9.45l3.5 7.49c.56.84 0 2.17-1.19 2.17H13.86c-1.05 0-1.68-1.26-1.26-1.96l3.5-7.7H6.65c-2.31 0-4.2-1.89-4.2-4.2V7.83c0-2.31 1.89-4.2 4.2-4.2zm9.38 47.6h23.24l-3.22-6.86H19.18l-3.15 6.86zm34.02-11.06c0 .77-.63 1.4-1.4 1.4h-42c-.77 0-1.4-.63-1.4-1.4v-1.89h44.8v1.89zm-44.8-4.69h44.8V7.83c0-.77-.63-1.4-1.4-1.4h-42c-.77 0-1.4.63-1.4 1.4v27.65z"
      fill="#155CEA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.84 9.16h6.02c.77 0 1.4.63 1.4 1.4v6.02c0 .77-.63 1.4-1.4 1.4h-6.02c-.77 0-1.4-.63-1.4-1.4v-6.02c0-.77.63-1.4 1.4-1.4zm1.4 5.95h3.22v-3.22h-3.22v3.22zM14.84 22.6h6.02c.77 0 1.4.63 1.4 1.4v6.02c0 .77-.63 1.4-1.4 1.4h-6.02c-.77 0-1.4-.63-1.4-1.4V24c0-.77.63-1.4 1.4-1.4zm1.4 6.02h3.22V25.4h-3.22v3.22z"
      fill="#155CEA"
    />
    <path
      d="M40.39 9.16H28.84c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h11.55c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4zM28.84 17.91h7.35c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4h-7.35c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4zM40.39 22.6H28.84c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h11.55c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4zM36.19 28.62h-7.35c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h7.35c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4z"
      fill="#155CEA"
    />
  </svg>
);

export default PropertyLoss;
