import {nanoid} from 'nanoid';

export const addUniqueIds = elem =>
  Array.isArray(elem) ? elem.map(el => ({ ...el, id: nanoid() })) : { ...elem, id: nanoid() };

export const handleMenuItemClick = (id, onClick = () => {}, param) => {
  const element = document.querySelector(`#${id}`);
  if (!element) return console.error(`Element with ID ${id} not found`);

  element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  onClick(param);
};

export const handleScrollToTop = (onClick = () => {}) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  onClick('Home');
};
