import styled from 'styled-components';

/* Constants */
import { COLORS } from 'lib/constants';

const Background = styled.div`
  background-color: ${COLORS.mapGrey};
`;

const Container = styled.section`
  padding: 0;
  padding: 12px 125px;

  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;

    @media screen and (max-width: 991px) {
      flex-basis: 100%;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    align-items: center;
    justify-items: center;
  }

  @media screen and (max-width: 1223px) {
    padding: 0;
  }

  @media screen and (max-width: 479px) {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }

  > div {
    @media screen and (max-width: 815px) {
      align-items: center;

      &:first-child {
        border-bottom: 1px solid ${COLORS.grey5};
      }

      > svg {
        margin-top: 0px;
      }
    }

    @media screen and (max-width: 479px) {
      > svg {
        margin-left: 0;
      }
    }
  }

  > div:first-child {
    > svg {
      margin-top: 0px;
    }
  }
`;

export { Container, Background };
