import React from 'react';

const LossAdjustment = props => (
  <svg width={56} height={57} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.78 10.07H27.16c.07-.28.07-.56.07-.84 0-3.08-2.52-5.6-5.6-5.6-3.08 0-5.6 2.52-5.6 5.6 0 .28 0 .56.07.84H4.55c-.77 0-1.4.63-1.4 1.4v41.16c0 .77.63 1.4 1.4 1.4h34.23c.77 0 1.4-.63 1.4-1.4V11.47c0-.77-.63-1.4-1.4-1.4zM21.7 6.43c1.54 0 2.8 1.26 2.8 2.8 0 .28-.07.56-.14.84h-5.32c-.07-.28-.14-.56-.14-.84 0-1.54 1.26-2.8 2.8-2.8zm8.4 6.44H13.23v3.92H30.1v-3.92zm-19.18 6.37c.21.21.56.35.91.35H31.5c.35 0 .63-.14.91-.35v26.32H10.92V19.24zM5.95 51.23h31.43V12.87H32.9v2.87h.91c.77 0 1.4.63 1.4 1.4v29.82c0 .77-.63 1.4-1.4 1.4H9.52c-.77 0-1.4-.63-1.4-1.4V17.14c0-.77.63-1.4 1.4-1.4h.91v-2.87H5.95v38.36z"
      fill="#155CEA"
    />
    <path
      d="M28.14 23.23H15.19c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h12.95c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4zM28.14 30.09H15.19c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h12.95c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4zM28.14 36.88H15.19c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h12.95c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4z"
      fill="#155CEA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.04 9.09h3.64c1.61 0 2.87 1.26 2.94 2.87v29.61c0 .21-.07.49-.21.7l-3.29 5.6c-.49.91-1.89.98-2.45 0l-3.29-5.6c-.14-.21-.21-.42-.21-.7V11.96c0-1.61 1.33-2.87 2.87-2.87zm1.82 35.28l1.89-3.22V11.96l-3.71-.07-.07 29.26 1.89 3.22z"
      fill="#155CEA"
    />
  </svg>
);

export default LossAdjustment;
