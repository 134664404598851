import React from 'react';

const VesselSurveys = props => (
  <svg width={56} height={57} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.6 32.19l4.41 1.89c.7.35 1.05 1.19.84 1.82L46.2 51.09c.35 0 .63 0 .91-.07.77-.07 1.47.42 1.61 1.19.07.77-.42 1.47-1.19 1.61-.77.14-3.22.56-7-.56-2.38-.7-4.9-.7-7.21.07-2.8.91-6.16.91-9.24-.07-2.38-.7-4.83-.63-7.21.07-2.87.84-6.16.91-9.24-.07-.77-.28-1.19-.98-.98-1.75.21-.77.98-1.19 1.75-.98.07.07.56.21.63.21L2.59 35.9c-.35-.7 0-1.54.7-1.82l4.41-1.89V21.62c0-2.31 1.89-4.2 4.2-4.2h3.08V7.83c0-2.31 1.89-4.2 4.2-4.2h16.94c2.31 0 4.2 1.89 4.2 4.2v9.59h3.08c2.31 0 4.2 1.89 4.2 4.2v10.57zM19.18 6.43c-.77 0-1.4.63-1.4 1.4v9.59h19.74V7.83c0-.77-.63-1.4-1.4-1.4H19.18zM10.5 21.62c0-.77.63-1.4 1.4-1.4h31.5c.77 0 1.4.63 1.4 1.4v9.31l-16.59-7.35c-.35-.14-.77-.14-1.12 0L10.5 30.93v-9.31zM41.23 50.6c.49.14 1.4.35 1.89.42l6.51-14.91-21.98-9.66-21.98 9.66 6.58 15.12c1.26 0 2.52-.21 3.71-.56 2.87-.91 5.95-.91 8.82-.07 2.66.84 5.46.84 7.63.07 2.87-1.05 5.95-.91 8.82-.07z"
      fill="#155CEA"
    />
    <path
      d="M21.63 8.46c-.77 0-1.4.63-1.4 1.4v4.06c0 .77.63 1.4 1.4 1.4.77 0 1.4-.63 1.4-1.4V9.86c0-.77-.63-1.4-1.4-1.4zM27.65 8.46c-.77 0-1.4.63-1.4 1.4v4.06c0 .77.63 1.4 1.4 1.4.77 0 1.4-.63 1.4-1.4V9.86c0-.77-.63-1.4-1.4-1.4zM33.67 8.46c-.77 0-1.4.63-1.4 1.4v4.06c0 .77.63 1.4 1.4 1.4.77 0 1.4-.63 1.4-1.4V9.86c0-.77-.63-1.4-1.4-1.4z"
      fill="#155CEA"
    />
  </svg>
);

export default VesselSurveys;
