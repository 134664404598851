import React from 'react';

const SvgComponent = props => (
  <svg width={45} height={45} {...props} viewBox="0 0 51 52">
    <title>{'5DD0AE31-1FD3-44E0-9D8E-036B312FF6D2'}</title>
    <g fill="none" fillRule="evenodd">
      <g fill="#155CEA" fillRule="nonzero">
        <path d="M46.65.63h-42C2.34.63.45 2.52.45 4.83v32.34c0 2.31 1.89 4.2 4.2 4.2h9.45l-3.5 7.7c-.42.7.21 1.96 1.26 1.96h27.58c1.19 0 1.75-1.33 1.19-2.17l-3.5-7.49h9.45c2.31 0 4.2-1.89 4.2-4.2V4.83c.07-2.31-1.82-4.2-4.13-4.2zm-9.38 47.6H14.03l3.15-6.86h16.87l3.22 6.86zm10.78-11.06c0 .77-.63 1.4-1.4 1.4h-42c-.77 0-1.4-.63-1.4-1.4v-1.89h44.8v1.89zm0-4.69H3.25V4.83c0-.77.63-1.4 1.4-1.4h42c.77 0 1.4.63 1.4 1.4v27.65z" />
        <path d="M18.86 6.16h-6.02c-.77 0-1.4.63-1.4 1.4v6.02c0 .77.63 1.4 1.4 1.4h6.02c.77 0 1.4-.63 1.4-1.4V7.56c0-.77-.63-1.4-1.4-1.4zm-1.4 5.95h-3.22V8.89h3.22v3.22zM18.86 19.6h-6.02c-.77 0-1.4.63-1.4 1.4v6.02c0 .77.63 1.4 1.4 1.4h6.02c.77 0 1.4-.63 1.4-1.4V21c0-.77-.63-1.4-1.4-1.4zm-1.4 6.02h-3.22V22.4h3.22v3.22zM38.39 6.16H26.84c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h11.55c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4zM26.84 14.91h7.35c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4h-7.35c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4zM38.39 19.6H26.84c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h11.55c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4zM34.19 25.62h-7.35c-.77 0-1.4.63-1.4 1.4 0 .77.63 1.4 1.4 1.4h7.35c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4z" />
      </g>
      <path d="M-2-2.52h56v56H-2z" />
    </g>
  </svg>
);

export default SvgComponent;
