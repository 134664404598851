import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Container, Position } from './styled';

const LeftTextbox = ({ text, center }) => {
  return (
    <Position className={center ? 'center' : ''}>
      <Container>
        <span>{text}</span>
        <div />
      </Container>
    </Position>
  );
};

LeftTextbox.propTypes = {
  text: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

LeftTextbox.defaultProps = {
  center: false,
};

export default LeftTextbox;
