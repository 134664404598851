import React, { useMemo } from 'react';
import Slider from 'react-slick';
import { Image, Transformation } from 'cloudinary-react';
import PropTypes from 'prop-types';

/* Components */
import { Container, SliderContainer } from './styled';
import withLine from 'components/ui/withLine';
import LeftTextbox from 'components/ui/LeftTextbox';

/* Helpers */
import { addUniqueIds } from 'lib/helpers';

/* Constants */
const settings = {
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4200,
  draggable: false,
  swipe: false,
  touchMove: false,
};

const Banner = ({ title, type, isCarousal }) => {
  const images = useMemo(
    () => ({
      port: addUniqueIds([
        { url: 'port-1', alt: 'Port with ship' },
        { url: 'port-4', alt: 'Port with ship' },
      ]),
      map: addUniqueIds({ url: 'map', alt: 'Worldwide map' }),
    }),
    []
  );

  const data = useMemo(() => images[type], [images, type]);

  if (!isCarousal) {
    return (
      <Container key={data.id} className={'contain'}>
        <Image
          responsive
          dpr="auto"
          crop="scale"
          width="auto"
          key={data.id}
          alt={data.alt}
          publicId={`gatsby-cloudinary/${data.url}`}
        >
          <Transformation quality="auto" fetchFormat="auto" />
        </Image>
        {title && <LeftTextbox text={title} center />}
      </Container>
    );
  }

  return (
    <SliderContainer>
      <Container>
        <Slider {...settings}>
          {data.map((item, i) => (
            <Image
              responsive
              dpr="auto"
              crop="scale"
              width="auto"
              publicId={`gatsby-cloudinary/${item.url}`}
              key={item.id}
              alt={item.alt}
              className={data.length - 1 === i ? 'custom' : ''}
            >
              <Transformation quality="auto" fetchFormat="auto" />
            </Image>
          ))}
        </Slider>
      </Container>

      {title && <LeftTextbox text={title} />}
      <div className="container anniversary-container">
        <Image
          width="auto"
          alt={'100th Anniversary'}
          className="anniversary"
          publicId={`gatsby-cloudinary/anniversary-3`}
        >
          <Transformation quality="auto" fetchFormat="auto" />
        </Image>
      </div>
    </SliderContainer>
  );
};

Banner.propTypes = {
  isCarousal: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf(['port', 'map']).isRequired,
};

Banner.defaultProps = {
  title: '',
  isCarousal: false,
};

export default withLine(Banner);
