import React from 'react';

const SvgComponent = props => (
  <svg height={14} width={14} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.912.787a6.296 6.296 0 00-6.3 6.3c0 3.483 2.818 6.3 6.3 6.3 3.483 0 6.3-2.817 6.3-6.3 0-3.482-2.817-6.3-6.3-6.3zm4.9 9.03H10.22c.192-.717.315-1.522.332-2.38h1.96a6.067 6.067 0 01-.7 2.38zM1.33 7.437h1.96c.017.858.14 1.645.332 2.38h-1.61a5.77 5.77 0 01-.682-2.38zm.682-3.08h1.593a10.014 10.014 0 00-.333 2.38H1.33a5.77 5.77 0 01.682-2.38zm5.25-.7V1.523c.788.193 1.488.998 1.96 2.136zm2.205.7c.21.718.35 1.523.385 2.38h-2.59v-2.38zM6.562 1.522v2.136h-1.96c.473-1.138 1.173-1.943 1.96-2.136zm0 2.835v2.38h-2.59c.035-.857.158-1.662.385-2.38zm-2.59 3.08h2.59v2.38H4.357a9.955 9.955 0 01-.385-2.38zm2.59 3.08v2.135c-.787-.192-1.487-.997-1.96-2.135zm.7 2.135v-2.135h1.96c-.472 1.138-1.172 1.943-1.96 2.136zm0-2.835v-2.38h2.59c-.035.858-.157 1.663-.385 2.38zm3.29-3.08a10.144 10.144 0 00-.332-2.38h1.592c.403.718.648 1.523.7 2.38zm.788-3.08H9.975c-.262-.7-.595-1.312-.997-1.767.945.367 1.75.997 2.362 1.767zM4.83 1.89c-.403.472-.735 1.067-.998 1.767H2.467c.63-.77 1.435-1.4 2.363-1.767zm-2.345 8.627H3.85c.263.7.595 1.313.998 1.768a5.662 5.662 0 01-2.363-1.768zm6.51 1.768c.402-.473.735-1.068.997-1.768h1.365c-.63.77-1.435 1.4-2.362 1.768z"
        fill="#bab8c4"
        fillRule="nonzero"
      />
      <path d="M0 0h14v14H0z" />
    </g>
  </svg>
);

export default SvgComponent;
