import React from 'react';

const ChevronUp = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    stroke="white"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-chevron-up"
    {...props}
  >
    <path d="M18 15l-6-6-6 6" />
  </svg>
);

export default ChevronUp;
