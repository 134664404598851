import React, { useMemo, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

/* Components */
import { Container } from './styled';
import DualBox from 'components/ui/DualBox';

/* HOCs */
import withLine from 'components/ui/withLine';

/* Helpers */
import { addUniqueIds } from 'lib/helpers';

const AboutUs = ({ intl, onEnter }) => {
  const [ref, inView] = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (inView) onEnter(intl.formatMessage({ id: 'aboutUs.title' }));
  }, [inView, intl, onEnter]);

  const aboutUsSections = useMemo(() => {
    return {
      firstColumn: addUniqueIds([
        {
          title: intl.formatMessage({ id: 'aboutUs.technology.title' }),
          text: intl.formatMessage({ id: 'aboutUs.technology.text' }),
          className: 'technology',
          image: 'tablet-kpi-2',
        },
        
      ]),
      secondColumn: addUniqueIds([
        {
          title: intl.formatMessage({ id: 'aboutUs.kpi.title' }),
          text: intl.formatMessage({ id: 'aboutUs.kpi.text' }),
          className: 'kpi',
          image: 'mobile-kpi',
        },
      ]),
      thirdColumn: addUniqueIds([
        {
          title: intl.formatMessage({ id: 'aboutUs.automotiveServices.title' }),
          text: intl.formatMessage({ id: 'aboutUs.automotiveServices.text' }),
          className: 'automotive-services',
          image: 'cars',
        },
      ]),
    };
  }, [intl]);

  return (
    <Container id={'AboutUs'} ref={ref}>
      <div>
        {aboutUsSections.firstColumn.map((section) => (
          <DualBox
            key={section.id}
            title={section.title}
            text={section.text}
            image={section.image}
            imageToTheLeft={1}
          />
        ))}
      </div>
      <div>
        {aboutUsSections.secondColumn.map((section) => (
          <DualBox
            key={section.id}
            title={section.title}
            text={section.text}
            image={section.image}
          />
        ))}
      </div>
      <div>
        {aboutUsSections.thirdColumn.map((section) => (
          <DualBox
            key={section.id}
            title={section.title}
            text={section.text}
            image={section.image}
            imageToTheLeft={1}
          />
        ))}
      </div>
    </Container>
  );
};

AboutUs.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onEnter: PropTypes.func.isRequired,
};

export default injectIntl(withLine(AboutUs));
