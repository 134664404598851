import styled from 'styled-components';

/* Constants */
import { COLORS, FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS } from 'lib/constants';

export const Position = styled.div`
  position: absolute;
  top: calc(50% - 90px);
  left: 0;
  width: 480px;
  height: 180px;

  @media screen and (max-width: 1223px) {
    top: calc(50% - 120px);
    height: 180px;
    width: 400px;
  }

  &.center {
    @media screen and (max-width: 1223px) {
      top: calc(50% - 60px);
    }
  }

  @media screen and (max-width: 767px) {
    width: 350px;
  }

  @media screen and (max-width: 479px) {
    width: 287px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: ${FONT_SIZES.size10};
    font-weight: ${FONT_WEIGHTS.weight1};
    color: ${COLORS.grey7};
    text-align: left;
    max-width: 62%;
    line-height: ${LINE_HEIGHTS.height1};
    letter-spacing: 1.2px;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      max-width: 50%;
      font-size: ${FONT_SIZES.size2};
    }

    @media screen and (max-width: 479px) {
      max-width: 60%;
      font-size: ${FONT_SIZES.size3};
    }
  }

  div {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 13px;
    width: 70px;
    background-color: ${COLORS.darkBlue};
  }
`;
