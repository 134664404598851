import styled from 'styled-components';

/* Styles */
import { FONT_SIZES, FONT_WEIGHTS, COLORS } from 'lib/constants';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 0 1px rgba(67, 90, 111, 0.47), 0 2px 4px -2px rgba(67, 90, 111, 0.3);
`;

export const Container = styled.nav`
  width: 100%;
  height: 95px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1200px) {
    padding: 0 32px 0 18px;
  }

  .logo {
    flex: 1;
    display: block !important;
  }

  img {
    max-width: 220px;
    object-fit: contain;
    cursor: pointer;
    position: relative;
    z-index: 20;
  }

  .menu--responsive {
    display: none;
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 991px) {
      display: block;
    }

    > div {
      > div:last-child {
        width: 100vw;
        height: 95px;
        position: relative;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 24px;
    height: 22px;
    right: 32px;
    top: 37px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${COLORS.darkBlue};
    height: 10% !important;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: ${COLORS.darkBlue};
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 25px;
    width: 42px;
    top: 30px !important;
    right: 32px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
    height: 30px !important;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${COLORS.darkBlue};
    padding: 111px 0 0;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    color: white;
    text-decoration: none;
    line-height: 69px;
    font-weight: ${FONT_WEIGHTS.weight2};
    font-size: 23px;
    letter-spacing: 2px;
    text-align: right;
    outline: none;

    .link-item {
      margin: 0 32px;
      cursor: pointer;
    }

    .locale {
      width: 100px;
      margin-left: auto;
      margin: 25px 32px 25px auto;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  > div:not(.menu--responsive) {
    flex-grow: 1;
    max-width: 640px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 991px) {
      display: none;
    }

    .links {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .link-item {
        cursor: pointer;
        position: relative;
        letter-spacing: 1.7px;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        color: ${COLORS.grey1};
        font-size: ${FONT_SIZES.size8};
        font-weight: ${FONT_WEIGHTS.weight2};

        @media screen and (max-width: 991px) {
          font-size: ${FONT_SIZES.size5};
          line-height: 19px;
          letter-spacing: 2px;
        }

        ~ .link-item {
          margin-left: 80px;
        }

        :hover,
        &.active {
          color: ${COLORS.darkBlue};
        }
      }

      .link-item::before,
      .link-item.active::before {
        content: '';
        width: 85%;
        height: 2px;
        left: 50%;
        bottom: -10px;
        position: absolute;
        transform-origin: center;
        background-color: ${COLORS.darkBlue};
        transform: translate(-50%, 0) scaleX(0);
        transition: transform 0.3s ease-in-out;
      }

      .link-item:hover::before,
      .link-item.active::before {
        transform: translate(-50%, 0) scaleX(1);
      }
    }
  }
`;
