import styled from 'styled-components';

/* Constants */
import { COLORS, FONT_SIZES, LINE_HEIGHTS } from 'lib/constants';

export const Container = styled.section`
  padding: 50px 0 83px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  hr {
    display: none;
  }

  @media screen and (max-width: 707px) {
    padding: 0;
    flex-direction: column;

    hr {
      display: block;
      width: 100%;
    }
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 18px;

    &.break {
      flex: 0;
      padding: 0;
    }

    @media screen and (max-width: 1023px) {
      &:not(.break) {
        align-items: flex-start;
        margin: 25px 14px;
      }

      &.break {
        flex-basis: 100%;
        height: 0;
      }

      &.footer__contact {
        flex: 2;
      }

      &.footer__logos {
        align-items: center;
      }
    }

    @media screen and (max-width: 707px) {
      &.break {
        display: none;
      }

      &.footer__browne--responsive {
        align-items: center;
        justify-content: center;
      }
    }

    @media screen and (max-width: 479px) {
      &.footer__logos,
      &.footer__contact,
      &.footer__browne--responsive {
        margin: 32px 14px;
      }
    }

    &:first-child {
      align-items: flex-start;
    }

    &:last-child {
      flex: 1;
      align-items: flex-end;

      @media screen and (max-width: 991px) {
        align-items: flex-start;
      }

      @media screen and (max-width: 707px) {
        align-items: center;
      }
    }
  }

  .footer {
    &__browne {
      padding: 0 18px;
      margin-top: -15px;

      @media screen and (max-width: 1023px) {
        margin: 25px 14px 25px 0px !important;
      }

      &--responsive {
        display: none;
      }

      @media screen and (max-width: 707px) {
        &--responsive {
          display: flex;

          img {
            width: 173px;
          }

          span {
            color: ${COLORS.grey0};
            font-size: ${FONT_SIZES.size9};
            line-height: ${LINE_HEIGHTS.height3};
          }
        }

        display: none;
      }

      img {
        width: 208px;
        cursor: pointer;
      }

      span {
        color: ${COLORS.grey0};
        font-size: ${FONT_SIZES.size9};
        line-height: ${LINE_HEIGHTS.height2};
        white-space: nowrap;
        margin-left: 14px;
      }
    }

    &__contact {
      img {
        height: 15px;
        width: 15px;
        object-fit: contain;
        margin-right: 12px;
      }

      span {
        color: ${COLORS.grey0};
        font-size: ${FONT_SIZES.size7};
        line-height: ${LINE_HEIGHTS.height2};
      }
    }

    &__menu {
      @media screen and (max-width: 707px) {
        display: none;
      }

      span {
        cursor: pointer;
        color: ${COLORS.grey0};
        font-size: ${FONT_SIZES.size8};
        line-height: ${LINE_HEIGHTS.height2};
        letter-spacing: 1.4px;

        &:hover {
          color: ${COLORS.darkBlue};
        }
      }
    }

    &__logos {
      flex: 2;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 12px;
      align-items: center;
      justify-items: center;
      height: 120px;

      img {
        height: 60px;
        object-fit: contain;
      }

      .tokkio-marine {
        height: 61%;

        @media screen and (max-width: 1200px) {
          height: 54%;
        }

        @media screen and (max-width: 479px) {
          height: 47%;
        }
      }
    }

    &__list {
      svg {
        margin-right: 8px;
        stroke: ${COLORS.textGrey};
        transform: scale(0.9);
      }

      > div,
      a {
        display: flex;
        align-items: center;
        height: 30px;
        margin-bottom: 4px;

        span {
          white-space: nowrap;
        }
      }
    }
  }
`;
