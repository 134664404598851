import React from 'react';

const SvgComponent = props => (
  <svg height={14} width={13} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.745.768C3.347.768.598 3.518.598 6.916s2.749 6.148 6.147 6.148 6.148-2.75 6.148-6.148S10.143.768 6.745.768zm4.782 8.812H9.973c.188-.7.307-1.486.324-2.322h1.913a5.92 5.92 0 01-.683 2.322zM1.297 7.258H3.21c.017.836.137 1.605.325 2.322H1.964a5.631 5.631 0 01-.666-2.322zm.667-3.006h1.554a9.771 9.771 0 00-.325 2.323H1.298c.051-.837.29-1.623.666-2.323zm5.123-.683V1.486c.768.188 1.451.973 1.912 2.083zm2.151.683c.205.7.342 1.486.376 2.323H7.087V4.252zM6.404 1.486v2.083H4.49c.461-1.11 1.144-1.895 1.913-2.083zm0 2.766v2.323H3.876c.035-.837.154-1.623.376-2.323zM3.876 7.258h2.528V9.58H4.252a9.715 9.715 0 01-.376-2.322zm2.528 3.005v2.083c-.769-.187-1.452-.973-1.913-2.083zm.683 2.083v-2.083h1.912c-.46 1.11-1.144 1.896-1.912 2.083zm0-2.766V7.258h2.527c-.034.836-.154 1.622-.376 2.322zm3.21-3.005a9.898 9.898 0 00-.324-2.323h1.554c.393.7.632 1.486.683 2.323zm.769-3.006H9.734c-.256-.683-.58-1.28-.974-1.725a5.525 5.525 0 012.306 1.725zM4.713 1.844c-.393.461-.717 1.042-.973 1.725H2.408c.615-.751 1.4-1.366 2.305-1.725zm-2.288 8.42h1.332c.256.682.58 1.28.973 1.724a5.525 5.525 0 01-2.305-1.725zm6.352 1.724c.393-.461.718-1.042.974-1.725h1.332c-.615.751-1.4 1.366-2.306 1.725z"
        fill="#fff"
        fillRule="nonzero"
      />
      <path d="M0 0h13.661v13.661H0z" />
    </g>
  </svg>
);

export default SvgComponent;
