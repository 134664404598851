import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Container } from './styled';
import { ChevronUp, ChevronDown } from 'components/ui/Icons';

/* Colors */
import { COLORS } from 'lib/constants';

const Tag = ({ service, activeService, onClick, services, withChevron }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleClick = () => {
    setIsCollapsed(prevBool => !prevBool);
    onClick();
  };

  return (
    <Container onClick={handleClick} className={services ? 'services' : 'map'}>
      {service.svg}
      {withChevron && (
        <div className="chevron">
          {isCollapsed ? <ChevronUp stroke={COLORS.grey3} /> : <ChevronDown stroke={COLORS.grey3} />}
        </div>
      )}
      <div className="content">
        <h3>{service.title}</h3>
        {service.text && <p className={activeService ? 'active' : ''}>{service.text}</p>}
      </div>
    </Container>
  );
};

Tag.propTypes = {
  service: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    svg: PropTypes.element,
  }),
  activeService: PropTypes.bool,
  services: PropTypes.bool,
  onClick: PropTypes.func,
  withChevron: PropTypes.bool,
};

Tag.defaultProps = {
  service: {
    title: '',
    text: '',
    svg: '',
  },
  activeService: false,
  services: false,
  withChevron: false,
  onClick: () => {},
};

export default Tag;
