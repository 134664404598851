import styled from 'styled-components';

/* Constants */
import { COLORS } from 'lib/constants';

const Line = styled.hr`
  position: relative;
  border: none;
  border-top: 1px solid ${COLORS.grey5};
  opacity: ${({ opacity = 1 }) => opacity};
`;

export default Line;
