import styled from 'styled-components';

export const Container = styled.div`
  &.responsive--991 {
    display: none;

    @media screen and (max-width: 991px) {
      display: block;

      .cta__img {
        height: 25px;
      }
    }
  }

  &.responsive--479 {
    display: none;

    @media screen and (max-width: 479px) {
      display: block;

      .cta__img {
        height: 37px;
      }
    }
  }

  .cta {
    display: flex;
    align-items: flex-end;

    p {
      cursor: pointer;
    }

    &__img {
      display: flex;
      align-items: center;
      margin-left: 12px;
      cursor: pointer;

      img {
        height: 8px;
      }

      &.collapsed {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }
`;
