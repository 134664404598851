import styled from 'styled-components';

/* Constants */
import { COLORS } from 'lib/constants';

export const SliderContainer = styled.div`
  width: 100%;
  height: 480px;
  position: relative;

  @media screen and (max-width: 1223px) {
    height: 440px;
  }

  .anniversary-container {
    position: absolute;
    right: calc(50% - 600px);
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 40%;

    @media screen and (max-width: 1200px) {
      right: 0;
    }

    @media screen and (max-width: 991px) {
      display: none;
    }

    .anniversary {
      position: relative;
      width: 130px;
      height: 130px;
      margin-right: 0;

      @media screen and (max-width: 1200px) {
        margin-right: 32px;
      }
    }
  }
`;

export const Container = styled.section`
  width: 100%;
  height: 480px;
  position: relative;
  background-color: ${COLORS.mapGrey};

  @media screen and (max-width: 1223px) {
    height: 440px;
  }

  .slick-slider {
    height: 100%;
    position: relative;

    .slick-list {
      height: 100%;
      width: 100%;
      overflow: hidden;

      .slick-track {
        height: 100%;
        display: flex !important;

        .slick-slide {
          height: 100%;

          > div {
            height: 100%;

            img {
              height: 100%;
              object-fit: cover;

              &.custom {
                @media screen and (max-width: 767px) {
                  object-position: right center;
                }
              }
            }
          }
        }
      }
    }
  }

  &.contain {
    height: 430px;

    @media screen and (max-width: 1223px) {
      height: 350px;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-position: calc(50% + 100px) center;

      @media screen and (max-width: 1223px) {
        object-fit: cover;
        object-position: 20% center;
      }
    }
  }
`;
