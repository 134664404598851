import styled from 'styled-components';
import Select from 'react-select';

/* Styles */
import { FONT_SIZES, FONT_WEIGHTS, COLORS } from 'lib/constants';

const customStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',
      flexBasis: '80px',
      letterSpacing: '1px',
      textAlign: 'center',
      color: COLORS.grey1,
      fontSize: FONT_SIZES.size8,
      fontWeight: FONT_WEIGHTS.weight2,
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      width: '90px',
      border: 'none',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media(max-width: 991px)': {
        background: 'transparent',
      },
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      border: 'none',
      boxShadow: 'none',
      '@media(max-width: 991px)': {
        background: 'transparent',
      },
    };
  },
  option: (provided, state) => {
    const { isSelected } = state;

    return {
      ...provided,
      cursor: 'pointer',
      letterSpacing: '1px',
      color: COLORS.grey1,
      fontSize: FONT_SIZES.size8,
      '@media(max-width: 991px)': {
        lineHeight: '25px',
        background: 'transparent',
        color: 'white',
        opacity: isSelected ? '1' : '0.6',
        padding: '8px 10px',
        display: 'flex',
        justifyContent: 'flex-start',
      },
    };
  },
  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      display: 'none',
    };
  },
  indicatorsContainer: (provided, state) => {
    const isMenuOpened = state.selectProps.menuIsOpen;

    return {
      ...provided,
      '@media(max-width: 991px)': {
        color: 'white',
      },
      svg: {
        '@media(max-width: 991px)': {
          color: 'white',
          transform: isMenuOpened ? 'rotate(0deg)' : 'rotate(180deg)',
        },
      },
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      color: COLORS.grey1,
      '@media(max-width: 991px)': {
        color: 'white',
      },
    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      paddingLeft: 22,
      width: 100,
      '> svg': {
        position: 'absolute',
        left: 0,
      },
    };
  },
};

export const StyledSelect = styled(Select).attrs({ styles: customStyles })``;
