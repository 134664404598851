import React from 'react';

const SvgComponent = props => (
  <svg width={56} height={57} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.75 46.995v-14.14c7.42-1.26 13.09-12.67 13.09-26.95 0-.77-.63-1.4-1.4-1.4H15.26c-.77 0-1.4.63-1.4 1.4 0 14.28 5.67 25.62 13.09 26.95v14.21c-3.36.56-5.95 3.29-5.95 6.51 0 .77.63 1.4 1.4 1.4h11.9c.77 0 1.4-.63 1.4-1.4 0-3.29-2.59-5.95-5.95-6.58zm-10.57-26.25h.84c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4H18.2c-.28-.91-.49-1.82-.7-2.8h3.85c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4h-4.34c-.21-1.61-.35-3.29-.42-5.04h12.53l1.19 3.64-3.01 3.64c-.28.35-.42.84-.28 1.26l1.82 6.51c.28.77.91 1.19 1.75.98.77-.21 1.19-.98.98-1.75l-1.61-5.81 3.15-3.64c.35-.35.42-.91.28-1.33l-1.12-3.5h7.84c-.35 12.53-5.6 22.82-11.69 22.82-3.71 0-7.07-3.71-9.24-9.38zm9.17 28.91c-1.89 0-3.57 1.05-4.2 2.45h8.4c-.63-1.47-2.31-2.45-4.2-2.45z"
      fill="#155CEA"
    />
  </svg>
);

export default SvgComponent;
