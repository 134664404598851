import styled from 'styled-components';

/* Constants */
import { FONT_SIZES, FONT_WEIGHTS, COLORS, LINE_HEIGHTS } from 'lib/constants';

export const Container = styled.div`
  display: flex;
  padding: 25px 0;
  width: 100%;
  justify-content: center;
  position: relative;

  > svg {
    margin-top: 0px;
  }

  .chevron {
    display: none;

    @media screen and (max-width: 595px) {
      display: block;
      position: absolute;
      right: 15px;
      top: 40px;
    }
  }

  &.map {
    > svg {
      margin-top: 0px;
      min-width: 45px;
      background-size: 45px 45px;

      @media screen and (max-width: 479px) {
        min-width: 35px;
        background-size: 35px 35px;
      }
    }

    .content {
      width: 300px;

      @media screen and (max-width: 991px) {
        margin-left: 14px;
      }

      @media screen and (max-width: 479px) {
        width: 240px;
      }

      h3 {
        margin-bottom: 0;
        line-height: ${LINE_HEIGHTS.height2};
        min-width: 300px;

        @media screen and (max-width: 479px) {
          min-width: 240px;
          font-size: ${FONT_SIZES.size5};
        }
      }
    }
  }

  &.services {
    > svg {
      min-width: 56px;
    }

    @media screen and (max-width: 991px) {
      padding: 25px 32px;
    }

    @media screen and (max-width: 595px) {
      border-top: 1px solid ${COLORS.grey5};

      &:first-child {
        border: none;
      }

      > svg {
        margin-top: 0;
      }

      .content {
        h3 {
          margin: 0;
          height: 57px;
          display: flex;
          align-items: center;
        }

        p {
          display: none;

          &:first-child {
            border: none;
          }

          &.active {
            display: block;
            margin-top: 25px;
          }
        }
      }
    }
  }

  .content {
    width: 240px;
    margin-left: 24px;

    h3 {
      margin-bottom: 12px;
      color: ${COLORS.darkBlue};
      font-weight: ${FONT_WEIGHTS.weight2};
    }

    p {
      color: ${COLORS.grey0};
      font-size: ${FONT_SIZES.size6};
      line-height: ${LINE_HEIGHTS.height2};
    }
  }
`;
