import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: row;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  > div {
    flex: 1;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;

    @supports (display: grid) {
      grid-template-columns: none;
    }
  }
`;
