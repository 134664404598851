import React, { useMemo, useEffect } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { useInView } from 'react-intersection-observer';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

/* Components */
import { Container, Title, Paragraphs } from './styled';
import CollapsableText from 'components/ui/CollapsableText';

/* HOCs */
import withLine from 'components/ui/withLine';

/* Helpers */
import { addUniqueIds } from 'lib/helpers';

const History = ({ intl, onEnter }) => {
  const [ref, inView] = useInView({ threshold: 0.6 });

  const title = useMemo(() => intl.formatMessage({ id: 'history.title' }), [intl]);

  useEffect(() => {
    if (inView) onEnter(intl.formatMessage({ id: 'aboutUs.title' }));
  }, [inView, intl, onEnter]);

  const paragraphsLeft = useMemo(
    () =>
      addUniqueIds([
        { text: `${intl.formatMessage({ id: 'history.paragraph1' })}`},
        { text: `${intl.formatMessage({ id: 'history.paragraph2' })}`},
      ]),
    [intl]
  );

  const paragraphsRight = useMemo(
    () =>
      addUniqueIds([
        { text: `${intl.formatMessage({ id: 'history.paragraph3' })}`},
        { text: `${intl.formatMessage({ id: 'history.paragraph4' })}`},
      ]),
    [intl]
  );

  return (
    <Container className="container" id={'History'} ref={ref}>
      <Title>
        <h2>{title}</h2>
        <div className="dash" />

        <div className="anniversary-container">
          <Image
            width="auto"
            alt={'100th Anniversary'}
            className="anniversary"
            publicId={`gatsby-cloudinary/anniversary-3`}
          >
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </div>
      </Title>

      <Paragraphs>
        <div>
          {paragraphsLeft.map((paragraph) => (
            <p className="paragraph" key={paragraph.id}>
              {paragraph.text}
            </p>
          ))}
        </div>
        <div>
          {paragraphsRight.map((paragraph) => (
            <p className="paragraph" key={paragraph.id}>
              {paragraph.text}
            </p>
          ))}
        </div>

        <CollapsableText className="paragraphs--responsive" responsive={'479'}>
          {paragraphsLeft.map((paragraph) => (
            <p key={paragraph.id}>{paragraph.text}</p>
          ))}
          {paragraphsRight.map((paragraph) => (
            <p key={paragraph.id}>{paragraph.text}</p>
          ))}
          <div className="people-container people-mobile">
            <Image
                alt={'Browne group'}
                className={'history__image'}
                publicId={`gatsby-cloudinary/OUR_COMPANY_htb5wg.jpg`}
              >
                <Transformation quality="auto" fetchFormat="auto" />
            </Image>
          </div>
        </CollapsableText>
      </Paragraphs>
      <Paragraphs className={'image-paragraph'}>
        <div className="people-container">
          <Image
            alt={'Browne group'}
            className={'history__image'}
            publicId={`gatsby-cloudinary/OUR_COMPANY_htb5wg.jpg`}
          >
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </div>
    </Paragraphs>
    </Container>
  );
};

History.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onEnter: PropTypes.func.isRequired,
};

export default injectIntl(withLine(History));
