import React, { useState, useMemo } from 'react';
import Headroom from 'react-headroom';
import { Image, Transformation } from 'cloudinary-react';
import { slide as Menu } from 'react-burger-menu';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

/* Components */
import { Container, Wrapper } from './styled';
import Line from 'components/ui/Line';
import LanguageSelect from 'components/ui/LanguageSelect';

/* Helpers */
import { handleMenuItemClick, handleScrollToTop, addUniqueIds } from 'lib/helpers';

const Navbar = ({ intl, onClick, active }) => {
  const [isSidebarOpened, setIsSidebarOpen] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(true);

  const toggleBodyOverflow = (sideBarState) => (document.body.style.overflowY = sideBarState ? 'hidden' : 'auto');

  const handleSidebar = useMemo(
    () => (state) => {
      const newIsOpenState = state ? state.isOpen : !isSidebarOpened;

      toggleBodyOverflow(newIsOpenState);
      setIsSidebarOpen(newIsOpenState);
    },
    [isSidebarOpened]
  );

  const handleSideBarItemClick = useMemo(
    () => (id) => {
      handleSidebar();
      handleMenuItemClick(id, onClick);
    },
    [handleSidebar, onClick]
  );

  const navbarItems = useMemo(
    () =>
      addUniqueIds([
        {
          key: 'Services',
          text: intl.formatMessage({ id: 'navbar.services' }),
          title: intl.formatMessage({ id: 'services.title' }),
          onClick: () => handleMenuItemClick('Services', onClick, intl.formatMessage({ id: 'navbar.services' })),
          onSidebarClick: () => handleSideBarItemClick('Services'),
        },
        {
          key: 'History',
          text: intl.formatMessage({ id: 'navbar.aboutUs' }),
          title: intl.formatMessage({ id: 'aboutUs.title' }),
          onClick: () => handleMenuItemClick('History', onClick, intl.formatMessage({ id: 'navbar.aboutUs' })),
          onSidebarClick: () => handleSideBarItemClick('History'),
        },
        {
          key: 'Brownet',
          text: intl.formatMessage({ id: 'navbar.brownet' }),
          title: intl.formatMessage({ id: 'brownet.title' }),
          onClick: () => handleMenuItemClick('Brownet', onClick, intl.formatMessage({ id: 'navbar.brownet' })),
          onSidebarClick: () => handleSideBarItemClick('Brownet'),
        },
      ]),
    [intl, onClick, handleSideBarItemClick]
  );

  const handleMenuOpen = () => setIsMenuOpened(true);
  const handleMenuClose = () => setIsMenuOpened(false);
  const handleMenuChange = () => toggleBodyOverflow(!isSidebarOpened);

  return (
    <Wrapper>
      <Container className="container">
        <div className="logo">
          <Image
            width="auto"
            alt={'Browne & co.'}
            publicId={`gatsby-cloudinary/browne-logo`}
            onClick={() => handleScrollToTop(onClick)}
          >
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </div>

        <div className="menu">
          <ul className="links">
            {navbarItems.map((navbarItem) => (
              <li
                key={navbarItem.id}
                onClick={navbarItem.onClick}
                className={`link-item ${active === navbarItem.title ? 'active' : ''}`}
              >
                {navbarItem.text}
              </li>
            ))}
          </ul>

          <LanguageSelect intl={intl} />
        </div>

        <div className="menu--responsive">
          <Menu right isOpen={isSidebarOpened} onStateChange={(state) => handleSidebar(state)}>
            <ul className="links">
              {navbarItems.map((navbarItem) => (
                <li key={navbarItem.id} className="link-item uppercase" onClick={navbarItem.onSidebarClick}>
                  {navbarItem.text}
                </li>
              ))}
            </ul>

            <div className={`locale-container ${isMenuOpened ? 'opened' : ''}`}>
              <Line opacity={0.1} />
              <div className="locale">
                <LanguageSelect
                  white
                  intl={intl}
                  menuPlacement="top"
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  onMenuChange={handleMenuChange}
                />
              </div>
            </div>
          </Menu>
        </div>
      </Container>
    </Wrapper>
  );
};

Navbar.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func,
  active: PropTypes.string,
};

Navbar.defaultProps = {
  onClick: () => {},
  active: '',
};

export default injectIntl(Navbar);
