import React, { useState, useMemo, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

/* Components */
import { Container } from './styled';
import Tag from 'components/ui/Tag';
import * as Icons from 'components/ui/Icons';

/* HOCs */
import withLine from 'components/ui/withLine';

/* Helpers */
import { addUniqueIds } from 'lib/helpers';

const Services = ({ intl, onEnter }) => {
  const [activeService, setActiveService] = useState('');
  const [ref, inView] = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (inView) onEnter(intl.formatMessage({ id: 'services.title' }));
  }, [inView, intl, onEnter]);

  const services = useMemo(
    () =>
      addUniqueIds([
        {
          name: 'cargoClaims',
          title: intl.formatMessage({ id: 'services.cargoClaims.title' }),
          text: intl.formatMessage({ id: 'services.cargoClaims.text' }),
          svg: <Icons.CargoClaims />,
        },
        {
          name: 'cargoSurvey',
          title: intl.formatMessage({ id: 'services.cargoSurvey.title' }),
          text: intl.formatMessage({ id: 'services.cargoSurvey.text' }),
          svg: <Icons.CargoSurvey />,
        },
        {
          name: 'lossAdjustment',
          title: intl.formatMessage({ id: 'services.lossAdjustment.title' }),
          text: intl.formatMessage({ id: 'services.lossAdjustment.text' }),
          svg: <Icons.LossAdjustment />,
        },
        {
          name: 'loadingControls',
          title: intl.formatMessage({ id: 'services.loadingControls.title' }),
          text: intl.formatMessage({ id: 'services.loadingControls.text' }),
          svg: <Icons.LoadingControls />,
        },
        {
          name: 'propertyLoss',
          title: intl.formatMessage({ id: 'services.propertyLoss.title' }),
          text: intl.formatMessage({ id: 'services.propertyLoss.text' }),
          svg: <Icons.PropertyLoss />,
        },
        {
          name: 'riskAnalysis',
          title: intl.formatMessage({ id: 'services.riskAnalysis.title' }),
          text: intl.formatMessage({ id: 'services.riskAnalysis.text' }),
          svg: <Icons.RiskAnalysis />,
        },
        {
          name: 'recoveryActions',
          title: intl.formatMessage({ id: 'services.recoveryActions.title' }),
          text: intl.formatMessage({ id: 'services.recoveryActions.text' }),
          svg: <Icons.RecoveryActions />,
        },
        {
          name: 'vesselSurveys',
          title: intl.formatMessage({ id: 'services.vesselSurveys.title' }),
          text: intl.formatMessage({ id: 'services.vesselSurveys.text' }),
          svg: <Icons.VesselSurveys />,
        },
        {
          name: 'salvageScale',
          title: intl.formatMessage({ id: 'services.salvageScale.title' }),
          text: intl.formatMessage({ id: 'services.salvageScale.text' }),
          svg: <Icons.SalvageScale />,
        },
      ]),
    [intl]
  );

  const handleActiveService = name => {
    name === activeService ? setActiveService('') : setActiveService(name);
  };

  return (
    <Container className="container" id="Services" ref={ref}>
      <h2>{intl.formatMessage({ id: 'services.title' })}</h2>

      <div className="grid">
        {services.map(service => (
          <Tag
            services
            withChevron
            key={service.id}
            service={service}
            activeService={activeService === service.name}
            onClick={() => handleActiveService(service.name)}
          />
        ))}
      </div>
    </Container>
  );
};

Services.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onEnter: PropTypes.func.isRequired,
};

export default injectIntl(withLine(Services));
