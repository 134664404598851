import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-repeat: no-repeat;
  }

  html {
    line-height: 1.15;
    word-break: break-word;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    min-height: 100%;
    overscroll-behavior: contain;
    font-family: 'Roboto', sans-serif;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  ul {
    list-style: none;
  }

  iframe {
    border: none;
  }

  button {
    all: unset;
    cursor: pointer;
  }

  img {
    border: none;
    height: auto;
    max-width: 100%;
  }

  a,
  a:active,
  a:focus {
    text-decoration: none;
  }

  [hidden] {
    display: none;
  }

  [disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
  }

  .uppercase {
    text-transform: uppercase;
  }

  #gatsby-focus-wrapper > div {
    margin-top: 95px;
  }
`;

export default GlobalStyle;
