export const LANGUAGES = {
  es: { value: 'es', label: 'ESP' },
  en: { value: 'en', label: 'ENG' },
  pt: { value: 'pt', label: 'POR' },
  ja: { value: 'ja', label: '日本' },
};

export const COLORS = {
  lightBlue: '#40BBE5',
  darkBlue: '#155CEA;',
  deepBlue: '#030A4A',
  textGrey: '#8184A4',
  mapGrey: '#F6F6F6',
  grey0: '#747586',
  grey1: '#6C6D7F',
  grey2: '#848599',
  grey3: '#BAB8C4',
  grey4: '#D8D8D8',
  grey5: '#EAEAEA',
  grey6: '#FAFAFA',
  grey7: '#464652'
};

export const FONT_SIZES = {
  size1: '26px',
  size2: '24px',
  size3: '21px',
  size4: '17px',
  size5: '16px',
  size6: '15px',
  size7: '14px',
  size8: '13px',
  size9: '11px',
  size10: '32px',
};

export const FONT_WEIGHTS = {
  weight1: '700',
  weight2: '500',
};

export const LINE_HEIGHTS = {
  height1: '37px',
  height2: '25px',
  height3: '15px',
};
