import styled from 'styled-components';

/* Constants */
import { COLORS, FONT_WEIGHTS } from 'lib/constants';

export const Button = styled.a`
  width: 175px;
  height: 43px;

  color: white;
  text-align: center;

  font-weight: ${FONT_WEIGHTS.weight1};
  background-color: ${COLORS.darkBlue};

  display: flex;
  align-items: center;
  justify-content: center;

  will-change: transform;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  :hover {
    background-color: white;
    color: ${COLORS.darkBlue};
  }
`;
