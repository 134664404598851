import React, { useEffect } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { useInView } from 'react-intersection-observer';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

/* Components */
import { Container, Background } from './styled';
import CtaButton from 'components/ui/CtaButton';
import CollapsableText from 'components/ui/CollapsableText';

const Brownet = ({ intl, onEnter }) => {
  const [ref, inView] = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (inView) onEnter(intl.formatMessage({ id: 'brownet.title' }));
  }, [inView, intl, onEnter]);

  return (
    <Background ref={ref}>
      <Container id="Brownet">
        <div className="brownet__image">
          <Image
            responsive
            dpr="auto"
            crop="scale"
            width="auto"
            alt={'Brownet app on Tablet'}
            publicId={`gatsby-cloudinary/tablet-app-2`}
          >
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </div>
        <div className="brownet__content">
          <div className="brownet__content__logo">
            <Image width="auto" alt={'Brownet logo'} publicId={`gatsby-cloudinary/brownet-logo`}>
              <Transformation quality="auto" fetchFormat="auto" />
            </Image>
          </div>
          <div className="brownet__content__text">
            <div className="brownet__content__text__default">
              <ul>
                <li>{intl.formatMessage({ id: 'brownet.paragraph1' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph2' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph3' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph4' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph5' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph6' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph7' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph8' })}</li>
              </ul>
            </div>
            <CollapsableText responsive={'991'}>
              <ul>
                <li>{intl.formatMessage({ id: 'brownet.paragraph1' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph2' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph3' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph4' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph5' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph6' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph7' })}</li>
                <li>{intl.formatMessage({ id: 'brownet.paragraph8' })}</li>
              </ul>
            </CollapsableText>
          </div>
          <div className="brownet__content__cta">
            <CtaButton link={'https://brownet.com'} cta={intl.formatMessage({ id: 'brownet.cta' })} />
          </div>
        </div>
      </Container>
    </Background>
  );
};

Brownet.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onEnter: PropTypes.func.isRequired,
};

export default injectIntl(Brownet);
