import React, { Fragment } from 'react';

/* Components */
import Line from 'components/ui/Line';

const withLine = WrappedComponent => {
  const hocComponent = ({ ...props }) => (
    <Fragment>
      <WrappedComponent {...props} />
      <Line />
    </Fragment>
  );

  return hocComponent;
};

export default withLine;
