import styled from 'styled-components';

/* Constants */
import { COLORS, FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS } from 'lib/constants';

export const Background = styled.div`
  padding: 83px 0;
  display: flex;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

export const Container = styled.section`
  width: 100%;
  display: flex;
  padding: 0 32px;

  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    max-height: 875px;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  @media screen and (max-width: 1200px) {
    @supports (display: grid) {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }

  ul {
    color: white;
    list-style-type: disc;
    margin-top: 12px;
    margin-left: 3px;
    list-style-position: inside;

    li ~ li {
      margin-top: 8px;
    }
    li {
      margin-left: 1.5em;
      text-indent: -1.5em;
      line-height: 25px;
    }
  }

  .brownet {
    &__image {
      flex: 2;
      height: 100%;
      max-height: inherit;

      @media screen and (max-width: 1200px) {
        flex: 1;
      }

      @media screen and (max-width: 991px) {
        height: 480px;
      }

      @media screen and (max-width: 767px) {
        height: 400px;
      }

      @media screen and (max-width: 479px) {
        height: 320px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 25% 15%;
      }
    }

    &__content {
      flex: 1;
      padding: 66px 55px;
      background-color: ${COLORS.deepBlue};
      display: flex;
      flex-direction: column;
      max-height: inherit;

      @media screen and (max-width: 1223px) and (min-width: 991px) {
        padding: 33px 55px;
      }

      @media screen and (max-width: 479px) {
        padding: 50px 32px;
      }

      &__logo {
        img {
          width: 145px;
        }
      }

      &__text {
        margin: 50px 0;

        @media screen and (max-width: 991px) {
          margin: 32px 0;
        }

        h2 {
          color: white;
          font-size: ${FONT_SIZES.size4};
          font-weight: ${FONT_WEIGHTS.weight1};
          line-height: ${LINE_HEIGHTS.height2};
        }

        p {
          color: white;
          font-size: ${FONT_SIZES.size4};
          line-height: ${LINE_HEIGHTS.height2};
          margin-top: 25px;

          @media screen and (max-width: 479px) {
            &:first-child {
              margin-top: 32px;
            }
          }
        }

        &__default {
          @media screen and (max-width: 991px) {
            display: none;
          }
        }

        .cta__img {
          padding-bottom: 12px;
        }
      }

      &__cta {
        @media screen and (max-width: 991px) {
          display: flex;
          justify-content: center;
        }

        @media screen and (max-width: 479px) {
          a {
            width: 100%;
          }
        }
      }
    }
  }
`;
