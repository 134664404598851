import React, { useState } from 'react';
import { CloudinaryContext } from 'cloudinary-react';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import 'intersection-observer';

/* Sections */
import Navbar from 'components/Navbar';
import Services from 'components/Services';
import History from 'components/History';
import AboutUs from 'components/AboutUs';
import LongDualTags from 'components/LongDualTags';
import Brownet from 'components/Brownet';
import Footer from 'components/Footer';

/* Components */
import SEO from 'components/ui/SEO';
import Banner from 'components/ui/Banner';
import GlobalStyles from 'components/ui/GlobalStyles';

const App = ({ pageContext, intl }) => {
  const { language } = pageContext.intl;
  const [title, setTitle] = useState(intl.formatMessage({ id: 'home.title' }));

  const handleChangeTitle = title => setTitle(title);

  return (
    <CloudinaryContext cloudName="dwfpsxlxf" secure>
      <Navbar onClick={handleChangeTitle} active={title} />
      <Banner title={intl.formatMessage({ id: 'banner1.title' })} type={'port'} isCarousal />

      <Services onEnter={handleChangeTitle} />
      <History onEnter={handleChangeTitle} />
      
      <AboutUs onEnter={handleChangeTitle} />
      <Banner type={'map'} />

      <LongDualTags />
      <Brownet onEnter={handleChangeTitle} />
      <Footer onClick={handleChangeTitle} />

      {/* Global Stylesheet & SEO */}
      <GlobalStyles />
      <SEO title={title} lang={language} />
    </CloudinaryContext>
  );
};

App.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(App);
