import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Button } from './styled';

const CtaButton = ({ cta, link }) => (
  <Button href={link} className="uppercase" target="_blank" rel="noopener noreferrer">
    {cta}
  </Button>
);

CtaButton.propTypes = {
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default CtaButton;
