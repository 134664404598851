import React, { useMemo } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

/* Components */
import { Container } from './styled';
import Line from 'components/ui/Line';
import { Email, Phone, Smartphone } from 'components/ui/Icons';

/* Helpers */
import { handleMenuItemClick, handleScrollToTop, addUniqueIds } from 'lib/helpers';

const Footer = ({ intl, onClick }) => {
  const footerItems = useMemo(
    () =>
      addUniqueIds([
        {
          title: intl.formatMessage({ id: 'footer.services' }),
          onClick: () => handleMenuItemClick('Services', onClick),
        },
        {
          title: intl.formatMessage({ id: 'footer.aboutUs' }),
          onClick: () => handleMenuItemClick('History', onClick),
        },
        {
          title: intl.formatMessage({ id: 'footer.brownet' }),
          onClick: () => handleMenuItemClick('Brownet', onClick),
        },
      ]),
    [intl, onClick]
  );

  const contactMediums = useMemo(
    () =>
      addUniqueIds([
        {
          name: 'email',
          value: intl.formatMessage({ id: 'footer.email' }),
          icon: <Email />,
          link: `mailto:${intl.formatMessage({ id: 'footer.email' })}`,
        },
        {
          name: 'phone',
          value: intl.formatMessage({ id: 'footer.phone' }),
          icon: <Phone />,
          link: 'tel:5491148497222',
        },
        {
          name: 'mobile',
          value: intl.formatMessage({ id: 'footer.mobile' }),
          icon: <Smartphone />,
          link: 'tel:5491135159000',
        },
      ]),
    [intl]
  );

  const logos = useMemo(
    () => addUniqueIds([{ img: 'aimu' }, { img: 'simi' }, { img: 'tokio-marine' }, { img: 'tmn' }]),
    []
  );

  return (
    <Container className={'container'}>
      <div className="footer__browne">
        <Image
          width="auto"
          alt={'Browne & co.'}
          publicId={`gatsby-cloudinary/browne-logo`}
          onClick={() => handleScrollToTop(onClick)}
        >
          <Transformation quality="auto" fetchFormat="auto" />
        </Image>
        <span>{intl.formatMessage({ id: 'footer.copyright' })}</span>
      </div>

      <div className="footer__contact">
        <div className="footer__list">
          {contactMediums.map((medium) => {
            if (medium.hasOwnProperty('link')) {
              return (
                <a href={medium.link} key={medium.id}>
                  {medium.icon}
                  <span>{medium.value}</span>
                </a>
              );
            }

            return (
              <div key={medium.id}>
                {medium.icon}
                <span>{medium.value}</span>
              </div>
            );
          })}
        </div>
      </div>

      <Line />
      <div className="break" />

      <div className="footer__menu">
        <div className="footer__list">
          {footerItems.map((item) => {
            return (
              <div key={item.id}>
                <span onClick={item.onClick} className="uppercase">
                  {item.title}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className={'footer__browne--responsive'}>
        <Image
          width="auto"
          alt={'Browne & co.'}
          publicId={`gatsby-cloudinary/browne-logo`}
          onClick={() => handleScrollToTop(onClick)}
        >
          <Transformation quality="auto" fetchFormat="auto" />
        </Image>
      </div>

      <div className="footer__logos">
        {logos.map((logo) => (
          <Image
            responsive
            dpr="auto"
            crop="scale"
            width="auto"
            key={logo.id}
            alt={logo.img}
            className={logo.img}
            publicId={`gatsby-cloudinary/${logo.img}`}
          >
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        ))}
      </div>

      <div className={'footer__browne--responsive'}>
        <span>{intl.formatMessage({ id: 'footer.copyright' })}</span>
      </div>
    </Container>
  );
};

Footer.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func,
};

Footer.defaultProps = {
  onClick: () => {},
};

export default injectIntl(Footer);
