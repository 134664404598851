import React from 'react';

const CargoSurvey = props => (
  <svg width={56} height={57} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.03 37.93l8.12 8.12c1.82 1.82 1.96 4.69 0 6.58-1.75 1.68-4.76 1.96-6.65 0l-8.12-8.12c-1.68.77-3.5 1.19-5.46 1.19-5.6 0-10.36-3.5-12.32-8.4H7.35c-2.31 0-4.2-1.89-4.2-4.2V7.83c0-2.31 1.89-4.2 4.2-4.2h27.51c2.31 0 4.2 1.89 4.2 4.2v13.44c3.71 2.38 6.16 6.51 6.16 11.2 0 1.96-.42 3.78-1.19 5.46zM25.2 6.43h-8.12v5.32l1.89-.98c.56-.21 1.05-.14 1.47.14l2.8 1.96 1.96-.56V6.43zM18.83 34.5H7.35c-.77 0-1.4-.63-1.4-1.4v-5.46h2.31c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4H5.95v-3.01h5.18c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4H5.95V7.83c0-.77.63-1.4 1.4-1.4h6.93v7.63c0 1.12 1.19 1.68 2.03 1.26l3.22-1.61 2.66 1.89c.28.21.7.28 1.19.21l3.64-1.05c.63-.14 1.05-.7 1.05-1.33v-7H35c.77 0 1.4.63 1.4 1.4v12.11c-1.33-.49-2.8-.77-4.34-.77-7.35 0-13.3 5.95-13.3 13.3-.07.7 0 1.4.07 2.03zm13.16-12.53c-5.81 0-10.5 4.76-10.5 10.5 0 .7.07 1.4.21 2.03h13.23c.77 0 1.4-.63 1.4-1.4V22.95c-1.33-.63-2.8-.98-4.34-.98zm0 21c-4.06 0-7.49-2.31-9.31-5.6h12.18c2.31 0 4.2-1.89 4.2-4.2v-8.33c2.03 1.89 3.36 4.62 3.36 7.63 0 5.74-4.69 10.5-10.43 10.5zm15.54 7.7c.7.7 1.96.7 2.66 0 .7-.7.7-1.82-.07-2.66l-7.56-7.56c-.77.98-1.68 1.89-2.66 2.66l7.63 7.56z"
      fill="#155CEA"
    />
    <path
      d="M27.16 25.61c-.77 0-1.4.63-1.4 1.4v2.45c0 .77.63 1.4 1.4 1.4.77 0 1.4-.63 1.4-1.4v-2.45c0-.77-.63-1.4-1.4-1.4zM31.5 25.61c-.77 0-1.4.63-1.4 1.4v2.45c0 .77.63 1.4 1.4 1.4.77 0 1.4-.63 1.4-1.4v-2.45c0-.77-.63-1.4-1.4-1.4z"
      fill="#155CEA"
    />
  </svg>
);

export default CargoSurvey;
