import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import PropTypes from 'prop-types';

/* Components */
import { Container } from './styled';

const DualBox = ({ title, text, image, imageToTheLeft }) => {
  return (
    <Container>
      <div className={`dual-box ${imageToTheLeft ? 'imageToTheLeft' : ''}`}>
        <div className={'dual-box__text'}>
          <span>{title}</span>
          {text.split('\n').map((item) => (
            <p>{item}</p>
          ))}
        </div>
        <div className={'dual-box__image'}>
          <Image responsive dpr="auto" crop="scale" width="auto" alt={title} publicId={`gatsby-cloudinary/${image}`}>
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </div>
      </div>
    </Container>
  );
};

DualBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  imageToTheLeft: PropTypes.number,
};

DualBox.defaultProps = {
  title: '',
  text: '',
  image: '',
  imageToTheLeft: 0,
};

export default DualBox;
