import React from 'react';

const ChevronDown = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    stroke="white"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-chevron-down"
    {...props}
  >
    <path d="M6 9l6 6 6-6" />
  </svg>
);

export default ChevronDown;
