import React from 'react';

const LoadingControls = props => (
  <svg width={56} height={56} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.15 11.06h-9.17L26.25 3.29c0-.07-.42-.14-.56-.14h-7.7c-.56 0-1.05.35-1.26.77L3.85 11.2c-.42.28-.7.7-.7 1.19V25.2c0 .77.63 1.4 1.4 1.4h8.12c.77 0 1.4-.63 1.4-1.4V13.86h2.52v38.29c0 .77.63 1.4 1.4 1.4h7.7c.77 0 1.4-.63 1.4-1.4V13.86H41.3v15.33h-3.78c-2.31 0-4.2 1.89-4.2 4.2v10.08c0 2.31 1.89 4.2 4.2 4.2H47.6c2.31 0 4.2-1.89 4.2-4.2V33.32c0-2.31-1.89-4.2-4.2-4.2h-3.5V13.86h8.05c.77 0 1.4-.63 1.4-1.4 0-.77-.63-1.4-1.4-1.4zM11.27 23.8H5.95v-9.94h5.32v9.94zm-1.4-12.74h6.72V7.28l-6.72 3.78zm9.52 2.8h3.57l-3.57 4.83v-4.83zm4.48 17.43l-4.48-4.83v10.92l4.48-6.09zm.49 19.46h-4.9v-5.53l4.9 5.32v.21zm-4.48-9.24l4.48 4.83V35.42l-4.48 6.09zm4.48-13.86l-4.48-4.83 4.48-6.09v10.92zm-4.9-16.59h4.9V5.95h-4.9v5.11zm7.7 0V6.72l9.24 4.34h-9.24zM49 33.32c0-.77-.63-1.4-1.4-1.4H37.52c-.77 0-1.4.63-1.4 1.4V43.4c0 .77.63 1.4 1.4 1.4H47.6c.77 0 1.4-.63 1.4-1.4V33.32z"
      fill="#155CEA"
    />
  </svg>
);

export default LoadingControls;
