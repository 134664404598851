import React, { useRef, useEffect } from 'react';
import { components } from 'react-select';
import { changeLocale } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

/* Components */
import { StyledSelect } from './styled';
import { Language, LanguageWhite } from 'components/ui/Icons';

/* Constants */
import { LANGUAGES } from 'lib/constants';

const LanguageSelect = ({ intl, onMenuOpen, onMenuClose, onMenuChange, white, menuPlacement }) => {
  const selectRef = useRef(null);

  const defaultLanguage = LANGUAGES[intl.locale];
  const languageOptions = Object.entries(LANGUAGES)
    .filter(([key]) => key !== intl.locale)
    .map(([key, language]) => language);

  const handleMenuItemClick = ({ value }) => {
    onMenuChange();
    changeLocale(value);
  };

  useEffect(() => {
    const inputs = document.querySelectorAll('input');

    if (inputs) {
      [...inputs].forEach(input =>
        input.setAttribute('aria-label', intl.formatMessage({ id: 'languageSelect.ariaLabel' }))
      );
    }
  }, [intl]);

  useEffect(() => {
    const isInternetExplorer = navigator.userAgent.indexOf('Trident') > -1;

    if (selectRef && selectRef.current && isInternetExplorer) {
      const control = selectRef.current.select.controlRef;
      const svg = control.querySelector('svg');
      svg.style.marginTop = '-6px';
    }
  }, [selectRef]);

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {white ? <LanguageWhite /> : <Language />}
          {children}
        </components.ValueContainer>
      )
    );
  };

  return (
    <StyledSelect
      ref={selectRef}
      isSearchable={false}
      menuPlacement={menuPlacement}
      options={languageOptions}
      defaultValue={defaultLanguage}
      onChange={handleMenuItemClick}
      onMenuOpen={() => onMenuOpen()}
      onMenuClose={() => onMenuClose()}
      aria-label={intl.formatMessage({ id: 'languageSelect.ariaLabel' })}
      inputProps={{
        'aria-label': intl.formatMessage({ id: 'languageSelect.ariaLabel' }),
      }}
      components={{ ValueContainer }}
    />
  );
};

LanguageSelect.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  onMenuChange: PropTypes.func,
  white: PropTypes.bool,
  menuPlacement: PropTypes.string,
};

LanguageSelect.defaultProps = {
  onMenuOpen: () => {},
  onMenuClose: () => {},
  onMenuChange: () => {},
  white: false,
  menuPlacement: 'bottom',
};

export default LanguageSelect;
