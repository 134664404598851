import React from 'react';

const SalvageScale = props => (
  <svg width={56} height={57} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.44 6.36L51.8 30.72c2.31 2.31 2.31 6.16 0 8.47L38.78 52.28c-2.52 2.45-6.37 2.24-8.54 0L5.81 27.85c-1.19-1.19-1.82-2.87-1.75-4.62l.42-6.58c.07-1.47.7-2.87 1.75-3.92l2.03-2.03-4.69-4.69c-.56-.56-.56-1.4 0-1.96s1.4-.56 1.96 0l4.76 4.76 2.03-2.03a5.962 5.962 0 013.92-1.75l6.58-.42c1.75-.07 3.43.56 4.62 1.75zm9.31 43.96l13.09-13.09c1.19-1.26 1.19-3.29 0-4.55L25.41 8.32c-.56-.56-1.47-.98-2.45-.91l-6.58.42c-.84 0-1.54.35-2.1.91l-2.03 2.03 3.71 3.71c.56.56.56 1.4 0 1.96s-1.4.56-1.96 0l-3.71-3.71-2.03 2.03c-.56.56-.84 1.33-.91 2.1l-.42 6.58c-.07.91.28 1.82.91 2.45L32.2 50.32c1.4 1.33 3.43 1.12 4.55 0z"
      fill="#155CEA"
    />
    <path
      d="M30.73 22.11c-.56-.56-1.4-.56-1.96 0l-7.14 7.14c-.56.56-.56 1.4 0 1.96s1.4.56 1.96 0l7.07-7.07c.63-.56.63-1.47.07-2.03zM36.61 27.99c-.56-.56-1.4-.56-1.96 0l-7.14 7.07c-.56.56-.56 1.4 0 1.96s1.4.56 1.96 0l7.07-7.07c.56-.56.56-1.4.07-1.96zM40.46 33.87l-7.07 7.07c-.56.56-.56 1.4 0 1.96s1.4.56 1.96 0l7.07-7.07c.56-.56.56-1.4 0-1.96s-1.4-.56-1.96 0z"
      fill="#155CEA"
    />
  </svg>
);

export default SalvageScale;
