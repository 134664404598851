import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';

/* Components */
import { Container } from './styled';
import { ChevronUp, ChevronDown } from 'components/ui/Icons';

const CollapsableText = ({ children, responsive, intl, history }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  const more = useMemo(
    () => (history ? intl.formatMessage({ id: 'history.collapse' }) : intl.formatMessage({ id: 'general.moreInfo' })),
    [intl, history]
  );
  const less = useMemo(() => intl.formatMessage({ id: 'general.lessInfo' }), [intl]);

  return (
    <Container className={`responsive--${responsive}`}>
      {!isCollapsed && (
        <div className={'cta'} onClick={handleCollapse}>
          <p>{more}</p>
          <div className={`cta__img`}>{isCollapsed ? <ChevronUp /> : <ChevronDown />}</div>
        </div>
      )}

      {isCollapsed && (
        <Fragment>
          {children}

          <div className={'cta collapsed'} onClick={handleCollapse}>
            <p>{less}</p>
            <div className={`cta__img collapsed`}>{isCollapsed ? <ChevronUp /> : <ChevronDown />}</div>
          </div>
        </Fragment>
      )}
    </Container>
  );
};

CollapsableText.propTypes = {
  children: PropTypes.array,
  responsive: PropTypes.string,
  history: PropTypes.bool,
};

CollapsableText.defaultProps = {
  children: [],
  responsive: '',
  history: false,
};

export default injectIntl(CollapsableText);
